import background_shelf from "~/assets/images/background_shelf.png";

export const DailyPick = () => {
  return (
    <div className="flex justify-center items-center bg-black/30 h-52 w-full rounded-xl relative">
      <img
        src={background_shelf}
        alt="background shelf"
        className="-z-10 absolute object-cover rounded-xl w-full h-52"
      />
      <h1 className="px-6 py-4 rounded text-center text-4xl text-white font-semibold">
        The Novel Database
      </h1>
    </div>
  );
};
