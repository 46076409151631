import { Link } from "@remix-run/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Users } from "lucide-react";
import React from "react";
import { transformLanguage } from "~/libs/utils";
import { Tables } from "~/types/database";

dayjs.extend(relativeTime);

type Props = {
  books: Tables<"ref_books">[];
};

export const LastUpdated = ({ books }: Props) => {
  return (
    <div className="relative mt-20 mb-8 bg-fore-secondary pattern-design rounded-lg py-2 md:py-6">
      <h2 className="px-4 pt-2 md:px-10 text-3xl text-typo-primary font-semibold">
        Latest updates
      </h2>
      <div className="px-2 md:px-10 mt-4 grid gap-2 md:gap-4 grid-cols-1 md:grid-cols-2">
        {books?.map((book) => (
          <Link to={"/book/" + book.slug} key={book.id}>
            <article
              key={book.id}
              className="md:mx-0 flex rounded h-full mx-auto md:w-auto overflow-clip bg-back-primary shadow shadow-slate-50 py-1.5 px-1.5"
            >
              <figure className="rounded-xl h-full flex shrink-0 w-16 items-start justify-center">
                <img
                  src={book.image}
                  className="object-cover w-16  rounded"
                  alt={book.title + " cover"}
                />
              </figure>

              <div className="text-sm flex justify-start gap-y-0.5 h-full flex-col w-full pl-4">
                {/* <Link to={`/book/${book.slug}`}> */}
                <p className="hover:underline font-semibold truncate">
                  {book.title}
                </p>
                {/* </Link> */}
                <div className="flex items-center gap-x-2 w-full">
                  {(!book.original_language ||
                    book.original_language !== "english") && (
                    <div className="rounded bg-white font-black py-0.5 text-sm">
                      {transformLanguage(book.original_language)}
                    </div>
                  )}
                  <div>
                    <span className="mr-1">Ch.</span>
                    <span className="mr-1">{book.number_of_chapters}</span>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-x-2">
                  {/* <div
                    className="flex gap-x-1 items-center text-gray-500"
                    // to={`/book/${book.slug}`}
                  >
                    <Users size={16} />
                    <h4 className="">Wuxiaworld</h4>
                  </div> */}
                  <div>
                    <span className="mr-1 text-gray-600">
                      {dayjs(book.last_updated).fromNow()}
                    </span>
                  </div>
                </div>
              </div>
            </article>
          </Link>
        ))}
      </div>
    </div>
  );
};
