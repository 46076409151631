import { Link } from "@remix-run/react";
import React from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "~/components/ui/carousel";
import { cn } from "~/libs/utils";
import { CardBook } from "~/routes/search/components/card.book";
import { Tables } from "~/types/database";

type Props = {
  books: Tables<"ref_books">[];
};

export const LastAdded = ({ books }: Props) => {
  const [api, setApi] = React.useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div className="my-10">
      <h2 className="mt-20 text-2xl font-semibold mb-6">Recently Added</h2>
      <Carousel
        setApi={setApi}
        opts={{ loop: true, align: "start" }}
        className="w-full"
      >
        <CarouselContent>
          {books.map((book, index) => (
            <CarouselItem
              key={index}
              className="w-auto basis-1/1 sm:basis-1/2 md:basis-1/4 lg:basis-1/6"
            >
            <CardBook book={book} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="flex justify-between items-center gap-2 -mt-10">
          <div className="flex gap-2 ">
            <CarouselPrevious className="hidden md:flex" />
            <CarouselNext className="hidden md:flex" />
          </div>
        </div>
      </Carousel>
    </div>
  );
};


const CardBook = ({ book }: { book: Tables<"ref_books"> }) => {
  const [imageLoading, setImageLoading] = React.useState(true);
  const [imageError, setImageError] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Link to={`/book/` + book.slug}>
      <div className="relative  w-52">
        <img
          src={imageError ? "/not-found-image.png" : book.image}
          alt={book.title + " cover"}
          className={cn("h-56 object-cover rounded-xl",  imageLoading ? "opacity-0" : "opacity-100")}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
        {imageLoading && (
          <div className="absolute top-0 left-0 h-full w-full rounded-xl bg-gray-200 animate-pulse"></div>
        )}
      </div>
      <p
        title={book.title}
        className="w-36 line-clamp-2 font-semibold text-gray-600"
      >
        {book.title}
      </p>
    </Link>
  );
}