import { Link } from "@remix-run/react";
import React from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "~/components/ui/carousel";
import { cn } from "~/libs/utils";
import { CardBook } from "~/routes/search/components/card.book";
import { Tables } from "~/types/database";

type Props = {
  books: Tables<"ref_books">[];
};

export const BooksRecommandation = ({ books }: Props) => {
  const [api, setApi] = React.useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div className="mt-10 mb-20 md:mb-10">
      <h2 className="mt-4 text-2xl font-semibold mb-6">Recommandation</h2>
      <Carousel
        setApi={setApi}
        opts={{ loop: true, align: "start" }}
        className="w-full"
      >
        <CarouselContent>
          {books.map((book, index) => (
            <CarouselItem
              key={index}
              className="w-auto basis-1/1 sm:basis-1/2 md:basis-1/4 lg:basis-1/6"
            >
              <Link to={`/book/` + book.slug}>
                <img
                  src={book.image}
                  alt={`${book.title}`}
                  className="h-56 object-cover rounded-xl"
                />
                <p
                  title={book.title}
                  className="w-36 line-clamp-2 font-semibold text-gray-600"
                >
                  {book.title}
                </p>
              </Link>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="flex justify-between items-center gap-2 -mt-10">
          <div className="flex gap-2 ">
            <CarouselPrevious className="hidden md:flex" />
            <CarouselNext className="hidden md:flex" />
          </div>
          {/* <div className="flex flex-wrap gap-1">
          {Array.from(Array(count).keys()).map((it, id) => (
            <button
              type="button"
              key={id}
              onClick={() => {
                console.log("id", id);
                api?.scrollTo(id);
              }}
              className={cn(
                "border-2 border-darkgray bg-darkgray rounded-full w-2 h-2",
                it + 1 !== current && "bg-white opacity-70"
                )}
                />
                ))}
                </div> */}
        </div>
      </Carousel>
    </div>
  );
};
